var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 clickable striped",attrs:{"loading":_vm.loading,"loading-text":"LOADING","headers":_vm.newheaders,"items":_vm.table,"items-per-page":_vm.meta.per_page,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.created_at).format(" D MMMM YYYY, HH:mm")))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.date).format(" D MMMM YYYY")))])]}},{key:"item.pivot.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.pivot.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.updated_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ],"dddd, D MMMM YYYY, HH:mm")))])]}},{key:"item.confirmed_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.confirmed_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ],"dddd, D MMMM YYYY, HH:mm")))])]}},{key:"item.issued_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.issued_on, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ],"dddd, D MMMM YYYY, HH:mm")))])]}},{key:"item.used_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.used_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ],"dddd, D MMMM YYYY, HH:mm")))])]}},{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.email_verified_at,'calendar')))])]}},{key:"item.posted_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.posted_at,'calendar')))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.currency_symbol))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")])]}},{key:"item.points.level",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":item.points.level,"dot":"","left":""}},[_vm._v(" "+_vm._s(item.points.level)+" ")])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-avatar',{staticClass:"mx-auto text-center",attrs:{"size":"20","color":"primary"}},[_c('v-img',{attrs:{"src":'/img/flags/'+item.country.toLowerCase()+'.png'}})],1)],1)]}},{key:"item.discount_amount",fn:function(ref){
var item = ref.item;
return [(item.type == 'fixed')?[_c('span',[_vm._v(_vm._s(item.discount_amount)+" €")])]:_vm._e(),(item.type == 'percentage')?[_c('span',[_vm._v(_vm._s(item.discount_amount)+" %")])]:_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-avatar',{staticClass:"mx-auto text-center",attrs:{"size":"25","color":"primary"}},[_c('img',{attrs:{"src":item.avatar.url.thumbnail,"alt":item.avatar.title}})])],1)]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary","to":{'name':_vm.name, 'params':{'id':item[_vm.id]}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [(item.thumbnail)?_c('div',{staticClass:"grey darken-4 py-3 px-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":item.thumbnail.url.image,"lazy-src":item.thumbnail.url.thumbnail,"contain":"","max-height":"150px","max-width":"150px"}})],1):_vm._e()]}},{key:"item.title-excerpt",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(_vm._s(item.description.substring(0,25)))])])]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))])]}},{key:"item.eventtitle_date",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.event.title))]),_c('br'),_c('small',[_vm._v("("+_vm._s(_vm.moment(item.event.date).format(" D MMMM YYYY"))+")")])]}},{key:"item.visible",fn:function(ref){
var item = ref.item;
return [(item.visible)?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'open' )?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.status == 'close' )?_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")]):_vm._e(),(item.status == 'on_hold' )?_c('v-icon',{attrs:{"color":"grey accent-4"}},[_vm._v("mdi-clipboard-text-clock-outline")]):_vm._e(),_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary","to":{'name':_vm.name, 'params':{'id':item[_vm.id]}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","target":"_blank","href":item[_vm.download],"disabled":!(item[_vm.download]),"loading":!(item[_vm.download])}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-download")]),_vm._v(" DOWNLOAD ")],1)]}},{key:"item.confirmed_participation",fn:function(ref){
var item = ref.item;
return [(item.confirmed_participation == null)?_c('div',[_c('v-btn',{attrs:{"block":"","color":"success","small":"","dark":""},on:{"click":function($event){return _vm.confirm(item)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.actions.confirm'))+" PRESENT ")])],1):_vm._e(),(item.confirmed_participation == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(item.confirmed_participation == false)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-cancel")]):_vm._e()]}}])},[_vm._t("default")],2),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page,"total-visible":5,"disabled":_vm.loading},on:{"input":function($event){return _vm.page(_vm.meta.current_page)}},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }