<template>
     <div>
       <v-data-table
            :loading="loading"
            loading-text="LOADING"
            :headers="newheaders"
            :items="table"
            :items-per-page="meta.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable striped"
            disable-pagination
       >
          
          <template v-slot:item.created_at="{ item }"  >
               <span>{{ moment(item.created_at).format(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.date="{ item }"  >
               <span>{{ moment(item.date).format(" D MMMM YYYY") }}</span>
          </template>
          <template v-slot:item.pivot.created_at="{ item }">
               <span>{{ [ item.pivot.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
               <span>{{ [ item.updated_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment("dddd, D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.confirmed_at="{ item }">
               <span>{{ [ item.confirmed_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment("dddd, D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.issued_on="{ item }">
               <span>{{ [ item.issued_on, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment("dddd, D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.used_at="{ item }">
               <span>{{ [ item.used_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment("dddd, D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.email_verified_at="{ item }">
               <span>{{ item.email_verified_at | moment('calendar') }}</span>
          </template>
          <template v-slot:item.posted_at="{ item }">
               <span>{{ item.posted_at | moment('calendar') }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
               <span>{{item.amount}} {{item.currency_symbol}}</span>
          </template>
          <template v-slot:item.active="{ item }">
               <v-icon color="green accent-4" v-if="item.active">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.points.level="{ item }">
               <v-badge
                    :color="item.points.level"
                    dot
                    left
               >
                {{item.points.level}}
               </v-badge>
          </template>
          <template v-slot:item.country="{ item }">
               <div class="text-center">
               <v-avatar size="20" color="primary" class="mx-auto text-center">
                     <v-img :src="'/img/flags/'+item.country.toLowerCase()+'.png'"></v-img>
               </v-avatar>
               </div>
          </template>
          <template v-slot:item.discount_amount="{ item }">
               <template v-if="item.type == 'fixed'">
               <span>{{item.discount_amount}} €</span>
               </template>
               <template v-if="item.type == 'percentage'">
               <span>{{item.discount_amount}} %</span>
               </template>
          </template>
          <template v-slot:item.avatar="{ item }">
               <div class="text-center">
                    <v-avatar size="25" color="primary" class="mx-auto text-center">
                         <img
                         :src="item.avatar.url.thumbnail"
                         :alt="item.avatar.title"
                         >
                    </v-avatar>
               </div>
          </template>
          <template v-slot:item.view="{ item }">
               <v-btn 
                    small
                    icon
                    color="primary"
                    :to="{'name':name, 'params':{'id':item[id]}}"
               >
               <v-icon small >mdi-eye</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.thumbnail="{ item }">
               <div v-if="item.thumbnail" class="grey darken-4 py-3 px-3 text-center"     >
                    <v-img
                    :src="item.thumbnail.url.image"
                    :lazy-src="item.thumbnail.url.thumbnail"
                    contain
                    class="mx-auto"
                    max-height="150px"       
                    max-width="150px"              
                    ></v-img>
               </div>
          </template>
          <template v-slot:item.title-excerpt="{ item }">
               <p class="my-1">
                    <b>{{item.title}}</b><br>
                    <small>{{item.description.substring(0,25)}}</small>
               </p>
          </template>
          <template v-slot:item.fullname="{ item }">
               <b>{{item.firstname}} {{item.lastname}}</b>
          </template>
          <template v-slot:item.eventtitle_date="{ item }">
               <b>{{item.event.title}}</b><br>
               <small>({{ moment(item.event.date).format(" D MMMM YYYY") }})</small>
          </template>
          
          
          <template v-slot:item.visible="{ item }">
               <v-icon color="green accent-4" v-if="item.visible">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.status="{ item }">
               <v-icon color="green accent-4" v-if="item.status == 'open' ">mdi-check-circle</v-icon>
               <v-icon color="red accent-4"  v-if="item.status == 'close' ">mdi-cancel</v-icon>
               <v-icon color="grey accent-4"  v-if="item.status == 'on_hold' ">mdi-clipboard-text-clock-outline</v-icon>
               {{item.status}}
          </template>
          <template v-slot:item.edit="{ item }">
               <v-btn 
                    small
                    icon
                    color="primary"
                    :to="{'name':name, 'params':{'id':item[id]}}"
               >
               <v-icon small >mdi-pencil</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.download="{ item }">
               <v-btn 
                    small
                    color="primary"
                    target="_blank"
                    :href="item[download]"
                    :disabled="!(item[download])"
                    :loading="!(item[download])"
               >
               <v-icon small class="mr-3" >mdi-download</v-icon> DOWNLOAD
               </v-btn>
          </template>
          <template v-slot:item.confirmed_participation="{ item }">
               <div v-if="item.confirmed_participation == null">
               <v-btn 
                    block 
                    color="success" 
                    small
                    dark 
                    @click="confirm(item)" 
                    >
                    {{$t('commons.actions.confirm')}} PRESENT
               </v-btn>
               </div>
               <v-icon v-if="item.confirmed_participation == true" color="success" >mdi-check</v-icon>
               <v-icon v-if="item.confirmed_participation == false" color="error" >mdi-cancel</v-icon>
              
          </template>
          <slot></slot>
        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="meta.current_page"
                :length="meta.last_page"
                :total-visible="5"
                :disabled="loading" 
                @input="page(meta.current_page)"
            ></v-pagination>
        </div>
    </div>
</template>

<script>
export default {
     name:"PaginatedTable",
     props:{
          name:{
               type: String,
               default: "single"
          },
          table: {
               type: Array,
               required: true,
          },
          headers: {
               type: Array,
          },
          edit: {
               type: Boolean,
               default: false
          },
          view: {
               type: Boolean,
               default: false
          },
          download: {
               type: String,
               default: null
          },
          id: {
               type: String,
                default: 'id'
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               default: true,
          }
     },
     data: () => ({
         datesKey: ['date_of_birth','start','end'],
         newheaders: [],
         datesTimeKey: ['created_at','updated_at','email_verified_at','phone_verified_at','confirmed_at','issued_on','posted_at']
     }),
     beforeMount(){
          this.getHeaders()
     },
     methods:{
          getHeaders(){
               if(!this.headers){
                    var keys = Object.keys(this.table);
                    keys.forEach(head => {
                         head  = {text:head, value: head,sortable: false}
                         //console.log(head)
                         this.newheaders.push(head)
                    });
               }else{
                    this.newheaders = this.newheaders.concat(this.headers);
               }
               
               if(this.view){
                    this.newheaders.push({text:'commons.actions.view', value: 'view', sortable: false, width: '5%', align: 'center'})
               }
               if(this.edit){
                    this.newheaders.push({text:'commons.actions.edit', value: 'edit', sortable: false, width: '5%', align: 'center'})
               }
               if(this.download){
                    this.newheaders.push({text:'commons.actions.download', value: 'download', sortable: false, width: '5%', align: 'center'})
               }

               if(this.newheaders.length >= 1){
                    this.newheaders.forEach((item) => {
                         item.text = this.$t(item.text);
                    });
               }

               console.log('this.newheaders',this.newheaders)
               this.newheaders;
          },
          page(page){
               this.meta.current_page = page
               this.$emit('update:meta', this.meta)
               this.$emit('change', this.meta)
          },
          confirm(item){
               this.$emit('confirm', item)
          },
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
     }
  }
</script>
<style >
.striped tr:nth-child(even) {background-color:  #0000000D;}
.striped tr:nth-child(odd) {background-color: #FFFFFF; }
</style>